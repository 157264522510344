import React, { useState } from "react"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Drawer from "@material-ui/core/Drawer"

import logo from "../assets/logo.svg"
import { List } from "react-bootstrap-icons"

import {
  Link,
  FormattedMessage,
  IntlContextConsumer,
  changeLocale,
} from "gatsby-plugin-react-intl"

import Language from "./languageSwitcher"

const CustomNavbar = () => {
  const [toggledMenu, setToggleMenu] = useState(false)

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setToggleMenu(open)
  }

  const menuItems = (
    <>
      <Link to="/how-it-works" className="nav-btn">
        <FormattedMessage id="nav_HowItWorks" defaultMessage="Jak to działa" />
      </Link>
      <Link to="/our-team" className="nav-btn">
        <FormattedMessage id="nav_OurTeam" defaultMessage="O nas" />
      </Link>
      <Link to="/faq" className="nav-btn">
        <FormattedMessage id="nav_Faq" defaultMessage="FAQ" />
      </Link>
      <Link to="/contact" className="nav-btn">
        <FormattedMessage id="nav_Contact" defaultMessage="Kontakt" />
      </Link>
      <Link to={process.env.GATSBY_PANEL_URL} className="nav-btn">
        <FormattedMessage id="nav_Login" defaultMessage="Logowanie" />
      </Link>
      <Link to="/order" id="orderLinkButton" className="order-nav-btn">
        <FormattedMessage id="nav_Order" defaultMessage="Zamów" />
      </Link>
    </>
  )

  return (
    <>
      <Box
        component={Container}
        display="flex"
        flexWrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
        id="site-navbar"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          className="nav-content"
        >
          <Link to="/" className="">
            <img src={logo} width="200px" alt="Carebits logo"></img>
          </Link>
          <Box className="mui-fixed" display={{ xs: "none", md: "contents" }}>
            {menuItems}
            <Language></Language>
          </Box>
          <Box display={{ xs: "contents", md: "none" }}>
            <Button className="navbar-toggler" onClick={toggleDrawer(true)}>
              <List></List>
            </Button>
            <Drawer
              anchor={"top"}
              open={toggledMenu}
              onClose={toggleDrawer(false)}
            >
              <Box
                className="nav-content-drawer"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                {menuItems}
                <Box alignSelf="flex-end" py={2}>
                  <Language></Language>
                </Box>
              </Box>
            </Drawer>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default CustomNavbar
