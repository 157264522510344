import React from "react"
import CircularNavButton from "./circularNavButton"
import NavButton from "../components/navButton"
import Markdown from "react-markdown"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"

import hearthWrapper from "../assets/footer-hearth.png"
import lightLogo from "../assets/light-logo.svg"

import { useIntl, Link } from "gatsby-plugin-react-intl"
import SocialLinks from "./socialLinks"

const Footer = props => {
  const intl = useIntl()
  const { cms } = props

  return (
    <>
      <footer className="page-footer">
        <Container>
          <Box position="relative">
            <img
              className="hearth-wrapper"
              src={hearthWrapper}
              alt="Footer hearth wrapper"
            ></img>
            <Box position="relative">
              <Box py={6}>
                <Grid container>
                  <Grid item className="banner-left" xs={12}>
                    <img
                      src={lightLogo}
                      width="250px"
                      alt="Carebits logo"
                    ></img>
                  </Grid>
                </Grid>
              </Box>
              <Grid container>
                <Grid container md={6} justifyContent="space-between">
                  <Grid item className="banner-left" md={6}>
                    <Markdown allowDangerousHtml>
                      {cms.data_administrator}
                    </Markdown>
                    <CircularNavButton
                      text={intl.formatMessage({
                        id: "footer_btnOurTeam",
                        defaultMessage: "O nas",
                      })}
                      link="/our-team"
                      className="white"
                    ></CircularNavButton>
                  </Grid>

                  <Grid item className="banner-left" md={5}>
                    <Markdown allowDangerousHtml>{cms.company_name}</Markdown>
                    <Markdown allowDangerousHtml>
                      {cms.company_address}
                    </Markdown>
                    <b>
                      <Markdown allowDangerousHtml>{cms.call_center}</Markdown>
                      <Markdown allowDangerousHtml>{cms.contact}</Markdown>
                    </b>
                    <Box mt={3}>
                      <SocialLinks></SocialLinks>
                    </Box>
                    <Box mt={3}>
                      <NavButton
                        text={intl.formatMessage({
                          id: "footer_btnContact",
                          defaultMessage: "Skontaktuj się",
                        })}
                        link="/contact"
                        className="primary"
                        id="contactLinkButton"
                      ></NavButton>
                    </Box>
                  </Grid>
                </Grid>

                <Box display={{ xs: "none", sm: "contents" }}>
                  <Grid item className="banner-left" xs={2}>
                    <ul className="links-list">
                      {cms.page_links.map((element, index) => {
                        return (
                          <li>
                            <Link to={element.link}>{element.title}</Link>
                          </li>
                        )
                      })}
                    </ul>
                  </Grid>
                </Box>
                <Grid item className="banner-left" xs={12} md={2}>
                  <ul className="links-list">
                    {cms.document_links.map((element, index) => {
                      return (
                        <li>
                          <Link to={element.link}>{element.title}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item className="banner-left" xs={12}>
                  <Box py={6}>
                    <Markdown allowDangerousHtml>
                      {cms.rights_reserved}
                    </Markdown>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </footer>
    </>
  )
}

export default Footer
