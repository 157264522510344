/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Container from "@material-ui/core/Container"
import Navbar from "./navBar"
import Footer from "./footer"
import Cookies from "./cookies"

const Layout = props => {
  const { footerCms } = props

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Navbar />
          <Container maxWidth="xl" disableGutters={true}>
            <main>{props.children}</main>
          </Container>
          <Cookies></Cookies>
          <Footer cms={footerCms}></Footer>
        </>
      )}
    />
  )
}

export default Layout
