import React from "react"
import { useLinksDictionary } from "../dictionaries/linksDictionary"
import { Facebook, Instagram } from "react-bootstrap-icons"
import Box from "@material-ui/core/Box"
import PropTypes from "prop-types"

const SocialLinks = ({ className }) => {
  const { links } = useLinksDictionary()
  return (
    <>
      <Box
        className={"social-links " + className}
        display="flex"
        flexDirection="row"
      >
        <Box
          component="a"
          href={links.instagram}
          target="_blank"
          rel="noreferrer"
          mr={3}
        >
          <Instagram></Instagram>
        </Box>

        <Box
          component="a"
          href={links.facebook}
          target="_blank"
          rel="noreferrer"
          mr={3}
        >
          <Facebook></Facebook>
        </Box>
      </Box>
    </>
  )
}

SocialLinks.propTypes = {
  className: PropTypes.string,
}

SocialLinks.defaultProps = {
  className: `light`,
}

export default SocialLinks
