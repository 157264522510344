import { useStaticQuery, graphql } from "gatsby"

export const useLinksDictionary = () => {
  const data = useStaticQuery(graphql`
    query {
      strapiOutputLinks {
        privacy_policy
        terms_of_use
        facebook
        instagram
      }
    }
  `)
  const links = data.strapiOutputLinks
  return { links }
}
