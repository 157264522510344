import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby-plugin-react-intl"
const NavButton = ({ id, text, link, className }) => {
  return (
    <Link id={id} className={"box-nav-btn " + className} to={link}>
      {text}
    </Link>
  )
}

NavButton.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  className: PropTypes.string,
}

NavButton.defaultProps = {
  id: ``,
  text: ``,
  link: `/`,
  className: "primary",
}

export default NavButton
