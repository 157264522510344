import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-react-intl"

const Language = () => {
  return (
    <div className="language-content">
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => (
            <a
              key={language}
              className={
                "lang-btn " + (language == currentLocale ? "active" : "")
              }
              onClick={() => changeLocale(language)}
            >
              {language.split("-")[0].toUpperCase()}
            </a>
          ))
        }
      </IntlContextConsumer>
    </div>
  )
}

export default Language
